<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          Производитель
        </th>
        <th class="text-left">
          Номер
        </th>
        <th class="text-left">
          Материал
        </th>
        <th class="text-left">
          Цвет
        </th>
        <th class="text-left">
          Кол-во
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(item,i) in stock"
          :key="i"
          v-bind:class="{'error white--text':item.count===0}"
      >
        <td>{{ item.manufacturer }}</td>
        <td>{{ item.prod_number }}</td>
        <td>{{ item.material }}</td>
        <td>{{ item.color }}</td>
        <td>{{ item.cnt }}</td>
      </tr>
      </tbody>
      <div class="d-flex mt-4">
        <v-pagination v-model="page" :length="pagesCount"/>
      </div>

    </template>
  </v-simple-table>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "StockStats",
  data() {
    return {
      stock: [],
      page: 1,
      pagesCount: 1,
    }
  },
  created() {
    this.getStockStats();
  },
  watch: {
    page() {
      this.getStockStats();
    }
  },
  methods: {
    getStockStats() {
      axios.get(`parts/dashboard?page=${this.page}`).then(body => {
        this.stock = body.parts;
        this.pagesCount = body.pagesCount;
      });
    },
  }
}
</script>

<style scoped>

</style>