export const orderStatuses = [
    {value: 'new', text: 'Новый'},
    {value: 'queue', text: 'В очереди на печать'},
    {value: 'modeling', text: 'На моделировании'},
    {value: 'printing', text: 'В печати'},
    {value: 'processing', text: 'На упаковке/Пост. обработке'},
    {value: 'moving', text: 'Перемещение на ПВЗ'},
    {value: 'moving_tk', text: 'Перемещение ТК'},
    {value: 'shipping', text: 'Готов к отгрузке'},
    {value: 'completed', text: 'Отгружен'},
    {value: 'canceled', text: 'Отменён'},
];

export const orderStatusesColor = [
    {value: 'new', text: '#99d2ff'},
    {value: 'queue', text: '#a3fffc'},
    {value: 'modeling', text: '#c3ffc5'},
    {value: 'printing', text: '#c3ffc5'},
    {value: 'processing', text: '#ffb2b2'},
    {value: 'moving', text: '#fcd6ff'},
    {value: 'moving_tk', text: '#fcd6ff'},
    {value: 'shipping', text: '#ffe5c4'},
    {value: 'completed', text: '#ffffff'},
    {value: 'canceled', text: '#ffffff'},
];

export const orderStatusLabel = (key) => {
    return orderStatuses.find(x => x.value === key)?.text;
}

export const orderStatusColor = (key) => {
    return orderStatusesColor.find(x => x.value === key)?.text;
}

export const paymentStatuses = [
    {value: 'not_paid', text: 'Не оплачен'},
    {value: 'part_paid', text: 'Частично оплачен'},
    {value: 'full_paid', text: 'Полностью оплачен'},
];

export const paymentStatusLabel = (key) => {
    return paymentStatuses.find(x => x.value === key)?.text;
}

export const orderLineTypes = [
    {value: 'production', text: 'Производство'},
    {value: 'service', text: 'Услуга'}
];
export const orderLineTypeLabel = (key) => {
    return orderLineTypes.find(x => x.value === key)?.text;
}